<template>
  <article>
    <!-- Início Principal -->
    <v-toolbar dense flat>
      <v-app-bar-nav-icon>
        <v-icon>fas fa-user</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Cadastro de Clientes</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn @click="modal.addCliente = true" class="mr-2" width="200" color="#018454" style="color: white;">
        <v-icon class="mr-2" color="white">fas fa-plus</v-icon>
        Adicionar
      </v-btn>

      <!-- Início ADD Cliente -->
      <v-dialog :retain-focus="false" v-model="modal.addCliente" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
          <v-toolbar flat color="#018454">
            <v-app-bar-nav-icon>
              <v-icon color="#FFFFFF">fas fa-plus</v-icon>
            </v-app-bar-nav-icon>

            <v-toolbar-title style="color: white;">Adicionar Cliente</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-app-bar-nav-icon @click="modal.addCliente = false;">
              <v-icon color="#FFFFFF">fas fa-times</v-icon>
            </v-app-bar-nav-icon>
          </v-toolbar>

          <v-card-text class="mt-2 pl-2 pr-2">
            <v-row dense>
              <v-card-title class="ml-2 black--text">
                <v-icon small class="mr-4">fas fa-user</v-icon>
                Dados do Cliente
              </v-card-title>
            </v-row>

            <v-card outlined>
              <v-row dense class="pa-4">
                <v-col cols="12" md="2" sm="3">
                  <v-text-field outlined label="Código" color="#018454" v-model="newCliente.codigo"></v-text-field>
                </v-col>

                <v-col cols="12" md="2" sm="3">
                  <v-text-field @change="mascaraCPF_CNPJ(1)" outlined label="CPF/CNPJ" color="#018454" v-model="newCliente.cpf_cnpj" clearable></v-text-field>
                </v-col>

                <v-col cols="12" md="2" sm="3">
                  <v-text-field outlined label="Nome/Razão Social" color="#018454" v-model="newCliente.nome_razao"></v-text-field>
                </v-col>

                <v-col cols="12" md="2" sm="3">
                  <v-text-field outlined label="Nome Fantasia" color="#018454" v-model="newCliente.nome_fantasia"></v-text-field>
                </v-col>

                <v-col cols="12" md="2" sm="3">
                  <v-text-field outlined label="RG/I.E." color="#018454" v-model="newCliente.rg_ie"></v-text-field>
                </v-col>

                <v-col cols="12" md="2" sm="3">
                  <v-text-field hint="Apenas números; Insira o DDD" @change="mascaraTelefone(1)" outlined label="Telefone" color="#018454" v-model="newCliente.telefone"></v-text-field>
                </v-col>

                <v-col cols="12" md="2" sm="3">
                  <v-text-field hint="Apenas números; Insira o DDD" @change="mascaraCelular(newCliente.celular, 1)" outlined label="Celular" color="#018454" v-model="newCliente.celular"></v-text-field>
                </v-col>

                <v-col cols="12" md="2" sm="3">
                  <v-text-field outlined label="Contato" color="#018454" v-model="newCliente.contato"></v-text-field>
                </v-col>

                <v-col cols="12" md="2" sm="3">
                  <v-text-field outlined label="Email" color="#018454" v-model="newCliente.email"></v-text-field>
                </v-col>

                <v-col cols="12" md="2" sm="3">
                  <v-text-field type="number" outlined label="Token" color="#018454" v-model="newCliente.token"></v-text-field>
                </v-col>
              </v-row>
            </v-card>
            
            <v-row dense class="mt-4">
              <v-card-title class="ml-2 pb-0 black--text">
                <v-icon small class="mr-4">fas fa-map-marker-alt</v-icon>
                Endereço
              </v-card-title>
            </v-row>

            <v-card outlined class="mt-4">
              <v-row dense class="pa-4">
                <v-col cols="12" md="2" sm="3">
                  <v-text-field hint="Apenas números" type="number" outlined label="CEP" @change="buscaCidade()" color="#018454" v-model="newCliente.cep"></v-text-field>
                </v-col>

                <v-col cols="12" md="2" sm="3">
                  <v-text-field outlined label="Logradouro" color="#018454" v-model="newCliente.logradouro"></v-text-field>
                </v-col>

                <v-col cols="12" md="2" sm="3">
                  <v-text-field outlined label="Número" color="#018454" v-model="newCliente.numero"></v-text-field>
                </v-col>

                <v-col cols="12" md="2" sm="3">
                  <v-text-field outlined label="Bairro" color="#018454" v-model="newCliente.bairro"></v-text-field>
                </v-col>

                <v-col cols="12" md="2" sm="3">
                  <v-text-field outlined label="Complemento" color="#018454" v-model="newCliente.complemento"></v-text-field>
                </v-col>

                <v-col cols="12" md="2" sm="3">
                  <v-text-field outlined label="Referência" color="#018454" v-model="newCliente.referencia"></v-text-field>
                </v-col>
                
                <v-col cols="12" md="2" sm="3">
                  <v-text-field outlined label="Cidade" :loading="camposCep" loader-height="4" :disabled="true" color="#018454" v-model="newCliente.cidade"></v-text-field>
                </v-col>

                <v-col cols="12" md="2" sm="3">
                  <v-text-field outlined label="UF" :loading="camposCep" loader-height="4" :disabled="true" color="#018454" v-model="newCliente.uf"></v-text-field>
                </v-col>

                <v-col cols="12" md="2" sm="3" class="d-flex justify-center">
                  <v-switch inset label="Local de Entrega" hide-details color="#018454" v-model="newCliente.local_entrega"></v-switch>
                </v-col>
              </v-row>
            </v-card>

            <v-row style="position: absolute; bottom: 20px; right: 20px;" class="ma-2" justify="end">
              <v-btn @click="AdicionarCliente()" class="ml-2" dense color="#018454" style="color: white;">
                <v-icon color="white" class="mr-2">fas fa-save</v-icon>
                Gravar
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Início Editar Cliente -->
      <v-dialog :retain-focus="false" v-model="modal.edit" fullscreen transition="dialog-bottom-transition">
        <v-card>
          <v-toolbar flat color="#018454">
            <v-app-bar-nav-icon>
              <v-icon color="white">fas fa-pen</v-icon>
            </v-app-bar-nav-icon>

            <v-toolbar-title class="white--text">Editar Cliente</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-app-bar-nav-icon @click="modal.edit = false;">
              <v-icon color="white">fas fa-times</v-icon>
            </v-app-bar-nav-icon>
          </v-toolbar>

          <v-card-text class="mt-2 pl-2 pr-2">
            <v-row dense>
              <v-card-title class="ml-2" style="color: rgba(0, 0, 0, 0.87);">
                <v-icon small class="mr-4" color="rgba(0, 0, 0, 0.54)">fas fa-user</v-icon>
                Dados do Cliente
              </v-card-title>
            </v-row>
                  
            <v-card outlined>
              <v-row dense class="pa-4">
                <v-col cols="12" md="2" sm="3">
                  <v-text-field outlined label="Código" color="#018454" v-model="editCliente.codigo"></v-text-field>
                </v-col>

                <v-col cols="12" md="2" sm="3">
                  <v-text-field @change="mascaraCPF_CNPJ(editCliente.cpf_cnpj, 0)" outlined label="CPF/CNPJ" color="#018454" v-model="editCliente.cpf_cnpj"
                  :value="editCliente.cpf_cnpj != null && !editCliente.cpf_cnpj.includes('.') ? mascaraCPF_CNPJ(editCliente.cpf_cnpj, 0) : ''"></v-text-field>
                </v-col>

                <v-col cols="12" md="2" sm="3">
                  <v-text-field outlined label="Nome/Razão Social" color="#018454" v-model="editCliente.nome_razao"></v-text-field>
                </v-col>

                <v-col cols="12" md="2" sm="3">
                  <v-text-field outlined label="Nome Fantasia" color="#018454" v-model="editCliente.nome_fantasia"></v-text-field>
                </v-col>

                <v-col cols="12" md="2" sm="3">
                  <v-text-field outlined label="RG/I.E." color="#018454" v-model="editCliente.rg_ie"></v-text-field>
                </v-col>

                <v-col cols="12" md="2" sm="3">
                  <v-text-field @change="mascaraTelefone(0)" outlined label="Telefone" color="#018454" v-model="editCliente.telefone"
                  :value="editCliente.telefone != null && !editCliente.telefone.includes('(') ? mascaraTelefone(0) : ''"></v-text-field>
                </v-col>

                <v-col cols="12" md="2" sm="3">
                  <v-text-field @change="mascaraCelular(editCliente.celular, 0)" outlined label="Celular" color="#018454" v-model="editCliente.celular"
                  :value="editCliente.celular != null && !editCliente.celular.includes('(') ? mascaraCelular(editCliente.celular, 0) : ''"></v-text-field>
                </v-col>

                <v-col cols="12" md="2" sm="3">
                  <v-text-field outlined label="Contato" color="#018454" v-model="editCliente.contato"></v-text-field>
                </v-col>

                <v-col cols="12" md="2" sm="3">
                  <v-text-field outlined label="Email" color="#018454" v-model="editCliente.email"></v-text-field>
                </v-col>

                <v-col cols="12" md="2" sm="3">
                  <v-text-field outlined label="Token" color="#018454" v-model="editCliente.token"></v-text-field>
                </v-col>
              </v-row>
            </v-card>
            
            <v-row dense class="mt-3">
              <v-card-title class="ml-2 pb-0" style="color: rgba(0, 0, 0, 0.87);">
                <v-icon small class="mr-4" color="rgba(0, 0, 0, 0.54)">fas fa-map-marker-alt</v-icon>
                Endereço
              </v-card-title>
            </v-row>

            <v-card outlined class="mt-4">
              <v-row dense class="pa-4">
                <v-col cols="12" md="2" sm="3">
                  <v-text-field outlined label="CEP" @change="buscaCidade()" color="#018454" v-model="editCliente.cep"></v-text-field>
                </v-col>

                <v-col cols="12" md="2" sm="3">
                  <v-text-field outlined label="Logradouro" color="#018454" v-model="editCliente.logradouro"></v-text-field>
                </v-col>

                <v-col cols="12" md="2" sm="3">
                  <v-text-field outlined label="Número" color="#018454" v-model="editCliente.numero"></v-text-field>
                </v-col>

                <v-col cols="12" md="2" sm="3">
                  <v-text-field outlined label="Bairro" color="#018454" v-model="editCliente.bairro"></v-text-field>
                </v-col>

                <v-col cols="12" md="2" sm="3">
                  <v-text-field outlined label="Complemento" color="#018454" v-model="editCliente.complemento"></v-text-field>
                </v-col>

                <v-col cols="12" md="2" sm="3">
                  <v-text-field outlined label="Referência" color="#018454" v-model="editCliente.referencia"></v-text-field>
                </v-col>
                
                <v-col cols="12" md="2" sm="3">
                  <v-text-field outlined label="Cidade" :disabled="true" color="#018454" v-model="editCliente.cidade"></v-text-field>
                </v-col>

                <v-col cols="12" md="2" sm="3">
                  <v-text-field outlined label="UF" :disabled="true" color="#018454" v-model="editCliente.uf"></v-text-field>
                </v-col>

                <v-col cols="12" md="2" sm="3" class="d-flex justify-center">
                  <v-switch inset label="Local de Entrega" hide-details color="#018454" v-model="editCliente.local_entrega"></v-switch>
                </v-col>
              </v-row>
            </v-card>

            <v-row style="position: absolute; bottom: 20px; right: 20px;" class="ma-2" justify="end">
              <v-btn @click="saveEdit()" class="ml-2" dense color="#018454" style="color: white;">
                <v-icon color="white" class="mr-2">fas fa-save</v-icon>
                Gravar
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-btn @click="modal.import = true, uploadFile = null" width="200" color="#018454" style="color: white;">
        <v-icon class="mr-2" color="white">fas fa-file-upload</v-icon>
        Importar(CSV)
      </v-btn>

      <!-- Início Importar CSV -->
      <v-dialog v-model="modal.import" width="fit-content">
        <v-card width="90vw" class="pa-2">
          <v-row dense align="center" class="mx-2">
            <v-card-title class="text-h6">
              <v-icon color="black" class="mr-5">fas fa-file</v-icon>
              Importar Clientes
            </v-card-title>
          </v-row>

          <v-card outlined>
            <v-row dense class="pa-2">
              <v-col>
                <v-file-input label="Importar Arquivos" hint="Informe o arquivo CSV." color="#018454"
                accept=".csv" clearable v-model="uploadFile" :loading="carregandoImport" loader-height="4"></v-file-input>
              </v-col>
            </v-row>
          </v-card>

          <v-row dense class="ma-2">
            <v-card-subtitle>
              <a href="/csv/exemplo.csv" download style="text-decoration: none;">
                <v-icon color="#1976d2" small class="mr-2">fas fa-file-upload</v-icon>
                Modelo de arquivo CSV
              </a>
            </v-card-subtitle>

            <v-spacer></v-spacer>

            <v-btn class="mt-2" color="#018454" @click="importFile()" style="color: white;">
              <v-icon small color="white" class="mr-2">fas fa-file-upload</v-icon>
              Importar
            </v-btn>
          </v-row>
        </v-card>
      </v-dialog>
    </v-toolbar>

    <v-card class="mt-4" outlined>
      <v-row dense justify="center">
        <v-card-title class="text-h6">Listagem de Clientes</v-card-title>

        <div style="position: absolute; right: 5px; top: 10px;">
          <v-btn width="200" type="button" color="#FF5252" @click="() => { limparFiltro(), carregarClientes() } "
            style="color: #FFFFFF;" class="mr-2">
            <v-icon dense class="mx-2">fas fa-broom</v-icon>
            Limpar
          </v-btn>

          <v-btn @click="modal.filtro = true" width="200" color="#018454" style="color: white;">
            <v-icon small class="mr-2" color="white">fas fa-search</v-icon>
            Filtrar
          </v-btn>
        </div>

        <!-- Início Filtro Clientes -->
        <v-dialog v-model="modal.filtro" width="fit-content">
          <v-card width="90vw" class="pa-2">
            <v-row dense align="center" class="mx-2">
              <v-card-title class="text-h6">
                <v-icon class="mr-4">fas fa-filter</v-icon>
                Filtrar Clientes
              </v-card-title>

              <v-spacer></v-spacer>

              <v-btn icon @click="modal.filtro = false">
                <v-icon color="red">fas fa-times</v-icon>
              </v-btn>
            </v-row>

            <v-card outlined>
              <v-row dense class="pa-4">
                <v-col cols="12" md="4" sm="4">
                  <v-text-field hide-details outlined color="#018454" label="Código" v-model="filtros.codigo" clearable></v-text-field>
                </v-col>

                <v-col cols="12" md="4" sm="4">
                  <v-text-field hide-details outlined color="#018454" label="Nome" v-model="filtros.nome" clearable></v-text-field>
                </v-col>

                <v-col cols="12" md="4" sm="4">
                  <v-text-field hide-details outlined color="#018454" label="CPF/CNPJ" v-model="filtros.cpf_cnpj" clearable></v-text-field>
                </v-col>
              </v-row>
            </v-card>

            <v-row dense class="ma-2" justify="end">
              <v-btn width="250" type="button" color="#FF5252" @click="() => limparFiltro()"
                style="color: #FFFFFF;" class="mr-2">
                <v-icon dense class="mx-2">fas fa-broom</v-icon>
                Limpar
              </v-btn>

              <v-btn width="250" @click="() => { carregarClientes(), modal.filtro = false }" color="#018454" style="color: white">
                <v-icon small color="white" class="mr-2">fas fa-search</v-icon>
                Filtrar
              </v-btn>
            </v-row>
          </v-card>
        </v-dialog>
      </v-row>

      <v-row class="mt-0">
        <v-card-title style="width: 100%;">
          <v-text-field class="mx-4" v-model="grid.clientes.pesquisa" color="#018656" prepend-icon="fas fa-search"
            label="Pesquisa rápida" single-line hide-details clearable sort-desc.sync="false">
          </v-text-field>
        </v-card-title>
      </v-row>

      <v-row style="height: calc(100vh - 363px);" class="overflow-y-auto">
        <v-col class="px-6 pb-6 pt-0">
          <v-data-table dense :headers="grid.clientes.cabecalho" :items="grid.clientes.items" :items-per-page="grid.clientes.porPagina"
          :loading="grid.clientes.carregando" hide-default-footer class="elevation-1 pt-4"  :search="grid.clientes.pesquisa" sort-by="codigo"
          sort-desc="false">
            <template #item.codigo="{ item }">
              <span>{{ item.codigo_interno }}</span>
            </template> 

            <template #item.nome="{ item }">
              <span>{{ item.nome }}</span>
            </template>

            <template #item.cidade="{ item }">
              <span>{{ item.cidade }} - {{ item.uf }}</span>
            </template>

            <template #item.actions="{ item }">
              <v-btn icon class="mr-2" @click="editarCliente(item)">
                <v-icon small color="primary">fas fa-pen</v-icon>
              </v-btn>

              <v-btn icon @click="excluirCliente(item)">
                <v-icon small color="red">fas fa-trash</v-icon>
              </v-btn>

            </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-row style="margin-top: 15px; margin-bottom: 5px; position: relative;">
          <v-col cols="8" style="max-height: 65px; position: absolute; left: 0; right: 0; margin: auto;">
            <v-pagination color="#018656" style="color: #018656" v-model="grid.clientes.paginaAtual" @input="mudarPagina"
            :length="Math.ceil(grid.clientes.totalRegistros / grid.clientes.porPagina)" :total-visible="5"></v-pagination>
          </v-col>

          <v-spacer></v-spacer>

          <v-col class="d-none d-md-flex mr-2" cols="2" style="max-height: 65px;">
            <v-select color="#018656" dense outlined label="Resultados por página" :items="grid.clientes.qtdsPaginacao"
              item-text="text" item-value="value" v-model="grid.clientes.porPagina"></v-select>
          </v-col>
        </v-row>
      </v-card>
  </article>
</template>

<script crossorigin="anonymous">
import { baseApiUrl, showSuccess, showError } from '@/global'
import axios from 'axios'

export default {
  name: "Clientes",
  data: () => ({
    uploadFile: null,
    carregandoImport: false,
    camposCep: false,
    modal: {
      addCliente: false,
      import: false,
      filtro: false,
      edit: false,
    },
    newCliente: {
      codigo: null,
      cpf_cnpj: '',
      nome_razao: null,
      nome_fantasia: null,
      rg_ie: null,
      telefone: '',
      showTelefone: false,
      celular: '',
      showCelular: false,
      contato: null,
      email: null,
      token: null,
      cep: null,
      logradouro: null,
      numero: null,
      bairro: null,
      complemento: null,
      referencia: null,
      cidade: null,
      cidade_codigo: null,
      uf: null,
      local_entrega: 0,
    },
    editCliente: {
      idCliente: null,
      codigo: null,
      cpf_cnpj: '',
      nome_razao: null,
      nome_fantasia: null,
      rg_ie: null,
      telefone: '',
      celular: '',
      contato: null,
      email: null,
      token: null,
      cep: null,
      logradouro: null,
      numero: null,
      bairro: null,
      complemento: null,
      referencia: null,
      cidade: null,
      cidade_codigo: null,
      uf: null,
      local_entrega: 0,
    },
    filtros: {
      codigo: null,
      nome: null,
      cpf_cnpj: null,
    },
    grid: {
      clientes: {
        cabecalho: [
          { text: "Código", value: "codigo" },
          { text: "Nome", value: "nome" },
          { text: "Cidade", value: "cidade" },
          { text: "Ações", value: "actions", sortable: false },
        ],
        items: [],
        carregando: false,
        totalRegistros: 0,
        paginaAtual: 1,
        qtdsPaginacao: [10, 25, 50, 100],
        porPagina: 50,
        pesquisa: '',
      }
    }
  }),

  created() {
    this.carregarClientes();
  },

  watch: {
    "grid.clientes.porPagina": {
      handler: function(val, oldVal){
        if(val != oldVal){
          this.carregarClientes();
        }
      }
    }
  },

  methods: {
    async carregarClientes(){
      this.grid.clientes.carregando = true;
      this.grid.clientes.items = [];

      let url = `${baseApiUrl}/clientes-listar?por_pagina=${this.grid.clientes.porPagina}`;

      if(this.filtros.codigo != null && this.filtros.codigo != ''){
        url += `&codigo_interno=${this.filtros.codigo}`;
        this.grid.clientes.paginaAtual = 1;
      }

      if(this.filtros.nome != null && this.filtros.nome != ''){
        url += `&nome=${this.filtros.nome}`;
        this.grid.clientes.paginaAtual = 1;
      }

      if(this.filtros.cpf_cnpj != null && this.filtros.cpf_cnpj != ''){
        url += `&cpf_cnpj=${this.filtros.cpf_cnpj}`;
        this.grid.clientes.paginaAtual = 1;
      }

      url += `&page=${this.grid.clientes.paginaAtual}`;

      await axios
        .get(url)
        .then((res) => {
          this.grid.clientes.items = res.data.dados.data;
          this.grid.clientes.totalRegistros = res.data.dados.total;
        })
        .catch(() => {
          showError("Não foi possível carregar os clientes.");
        })
        .finally(() => (this.grid.clientes.carregando = false));
    },

    mudarPagina(pagina){
      this.grid.clientes.paginaAtual = pagina;
      this.carregarClientes();
    },

    async buscaCidade(){
      if(this.newCliente.cep.length > 0){
        this.camposCep = true;
        let url = `${baseApiUrl}/busca-cep?cep=${this.newCliente.cep}`;
  
        await axios
          .get(url)
            .then((res) => {
              this.newCliente.cidade_codigo = res.data[0].codigo;
              this.newCliente.cidade = res.data[0].cidade;
              this.newCliente.uf = res.data[0].uf;
            }).catch(() => {
              showError("Cidade/Estado não localizados. Verifique o CEP digitado.");
            })
            .finally(() => {this.camposCep = false;});
      }
    },

    async AdicionarCliente(){
      let url = `${baseApiUrl}/clientes-gravar`;

      await axios
        .post(url, {
          "codigo_interno": this.newCliente.codigo,
          "nome": this.newCliente.nome_razao,
          "fantasia": this.newCliente.nome_fantasia,
          "cpf_cnpj": this.newCliente.cpf_cnpj.replace(/[./-]/g, ''),
          "rg_ie": this.newCliente.rg_ie,
          "contato": this.newCliente.contato,
          "email": this.newCliente.email,
          "cep": this.newCliente.cep,
          "cidade": this.newCliente.cidade_codigo,
          "endereco": this.newCliente.logradouro,
          "numero": this.newCliente.numero,
          "bairro": this.newCliente.bairro,
          "complemento": this.newCliente.complemento,
          "referencia": this.newCliente.referencia,
          "local_entrega_diferente": this.newCliente.local_entrega,
          "telefones": {
            "telefone": {
              "numero": this.newCliente.telefone.replace(/[() -]/g, ''),
              "celular": false
            },
            "celular": {
              "numero": this.newCliente.celular.replace(/[() -]/g, ''),
              "celular": true
            }
          },
        })
          .then(() => {
            this.$swal({
              title: 'Sucesso!!!',
              text: 'Cliente cadastrado com sucesso!',
              icon: 'success',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#4BB543',
              allowOutsideClick: true,
            }).finally(() => (this.modal.addCliente = false, this.carregarClientes()));
          })
          .catch(() => {
            showError("Não foi possível realizar o cadastro, verifique se as informações foram preenchidas corretamente.");
          })
          
    },

    async importFile(){
      this.carregandoImport = true;
      let url = `${baseApiUrl}/clientes-importar`

      const data = new FormData();
      data.append('arquivo', this.uploadFile);

      let config = { header : { 'Content-Type' : 'multipart/form-data' } };

      await axios
        .post(url, data, config)
          .then((res) => {
            this.carregandoImport = false;
            this.$swal({
              title: 'Sucesso!!!',
              text: `${res.data.dados}`,
              icon: 'success',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#4BB543',
              allowOutsideClick: true,
            })
            .finally(() => (this.modal.import = false, this.carregarClientes()));
          })
          .catch(() => {
            this.carregandoImport = false;
            this.$swal({
              title: 'Que pena!!!',
              html: 'Não foi possível realizar a importação. <br> Verifique se o arquivo está de acordo com o modelo.',
              icon: 'warning',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#4BB543',
              allowOutsideClick: true,
            })
          })          
    },

    editarCliente(cliente){
      this.modal.edit = true;
      this.editCliente.idCliente = cliente.idclientes;

      this.editCliente.codigo = cliente.codigo_interno;
      this.editCliente.nome_razao = cliente.nome
      this.editCliente.nome_fantasia = cliente.fantasia;
      this.editCliente.cpf_cnpj = cliente.cpf_cnpj;
      this.editCliente.rg_ie = cliente.rg_ie;
      this.editCliente.contato = cliente.contato;
      this.editCliente.email = cliente.email;
      this.editCliente.token = cliente.token;
      this.editCliente.cep = cliente.cep;
      this.editCliente.cidade = cliente.cidade;
      this.editCliente.cidade_codigo = cliente.codigo;
      this.editCliente.logradouro = cliente.endereco;
      this.editCliente.numero = cliente.numero;
      this.editCliente.bairro = cliente.bairro;
      this.editCliente.complemento = cliente.complemento;
      this.editCliente.referencia = cliente.referencia
      this.editCliente.local_entrega = cliente.local_entrega_diferente;
      
      let telefones = JSON.parse(cliente.telefones);
      if(typeof(telefones) != "object")
        telefones = JSON.parse(telefones);

      this.editCliente.celular = telefones.celular.numero;
      this.editCliente.telefone = telefones.telefone.numero;
    },

    async saveEdit(){
      let url = `${baseApiUrl}/clientes-atualizar?idclientes=${this.editCliente.idCliente}`;

      this.$swal({
        title: 'Confirmação',
        html: `Confirma as alterações no cliente ${this.editCliente.nome_razao}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
      }).then( async(result) => {
        if(result.isConfirmed){
          await axios
            .put(url, {
              "codigo_interno": this.editCliente.codigo,
              "nome": this.editCliente.nome_razao,
              "fantasia": this.editCliente.nome_fantasia,
              "cpf_cnpj": this.editCliente.cpf_cnpj.replace(/[./-]/g, ''),
              "rg_ie": this.editCliente.rg_ie,
              "contato": this.editCliente.contato,
              "email": this.editCliente.email,
              "cep": this.editCliente.cep,
              "cidade": this.editCliente.cidade_codigo,
              "endereco": this.editCliente.logradouro,
              "numero": this.editCliente.numero,
              "bairro": this.editCliente.bairro,
              "complemento": this.editCliente.complemento,
              "referencia": this.editCliente.referencia,
              "local_entrega_diferente": this.editCliente.local_entrega,
              "telefones": {
                "telefone": {
                  "numero": this.editCliente.telefone.replace(/[() -]/g, ''),
                  "celular": false
                },
                "celular": {
                  "numero": this.editCliente.celular.replace(/[() -]/g, ''),
                  "celular": true
                }
              },
            })
              .then((res) => {
                this.$swal({
                  title: 'Sucesso!!!',
                  text: `${res.data.dados}`,
                  icon: 'success',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#4BB543',
                  allowOutsideClick: true,
                }).finally(() => (this.modal.edit = false, this.carregarClientes()));
              })
              .catch(() => {
                this.$swal({
                  title: 'Que pena!!!',
                  text: `Não foi possível salvar as alterações.`,
                  icon: 'warning',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#4BB543',
                  allowOutsideClick: true,
                });
              });
        }
      });
    },

    excluirCliente(cliente){
      this.$swal({
        title: 'Confirmação',
        html: `Confirma a exclusão do cliente ${cliente.nome}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
      }).then(async (result) => {
        if(result.isConfirmed) {
          let url = `${baseApiUrl}/clientes-excluir?idclientes=${cliente.idclientes}`;
          await axios
            .delete(url)
              .then((res) => {
                showSuccess(res.data.dados);
                this.carregarClientes();
              }
            )
            .catch(() => {
              showError("Não foi possível realizar a exclusão.");
            }
          )
        }
      })
    },

    mascaraCPF_CNPJ(codigo){
      if(codigo == 1){
        var r = this.newCliente.cpf_cnpj.replace(/\D/g, "");
      }else{
        var r = this.editCliente.cpf_cnpj.replace(/\D/g, "");
      }
      
      if(r.length > 8){
        r = r.replace(/(\d{3})(\d{3})(\d{3})/, "$1.$2.$3-")
      }else if(r.length > 5){
        r = r.replace(/(\d{3})(\d{3})/, "$1.$2.");
      }else if(r.length > 2){
        r = r.replace(/(\d{3})/, "$1.");
      }

      if(codigo == 1){
        this.newCliente.cpf_cnpj = r;
      }else{
        this.editCliente.cpf_cnpj = r;
      }
    },

    mascaraTelefone(codigo){
      if(codigo == 1){
        var r = this.newCliente.telefone.replace(/\D/g, "");
      }else{
        var r = this.editCliente.telefone.replace(/\D/g, "");
      }
      r = r.replace(/^0/, "");

      if (r.length > 10) {
        r = r.replace(/^(\d\d)(\d{4})(\d{4}).*/, "($1) $2-$3");
      }else if(r.length > 5){
        r = r.replace(/^(\d\d)(\d{0,4})(\d{0,4})/, "($1) $2-$3");
      }else if(r.length > 2){
        r = r.replace(/^(\d\d)(\d{0,4})/, "($1) $2");
      }else{
        r = r.replace(/^(\d)/, "($1");
      }

      if(codigo == 1){
        this.newCliente.telefone = r;
      }else{
        this.editCliente.telefone = r;
      }
    },

    mascaraCelular(codigo, add){
      if(codigo != null){
        let update = "";
        if(codigo.replace(/[() -]/g, '').length == 11){
          codigo = codigo.replace(/[() -]/g, '');
          update = '(' + codigo.slice(0, 2) + ') ' + codigo.slice(2, 7) + '-' + codigo.slice(7);
        }
        if(add == 1){
          this.newCliente.celular = update;
        }else{
          this.editCliente.celular = update;
        }
      }
    },

    limparFiltro(){
      this.filtros.codigo = null;
      this.filtros.nome = null;
      this.filtros.cpf_cnpj = null;
      this.grid.clientes.porPagina = 50;
    }
  }
}
</script>

<style>
.btn-import:hover {
  color: #018454 !important;
}
</style>